import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Use createRoot for rendering
const root = createRoot(document.getElementById('root')); // Create a root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Register or unregister service worker
serviceWorker.unregister();
