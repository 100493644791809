import React from "react";
import './footer.css'; // Ensure this is the correct path
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-section">
            <h4>About Us</h4>
            <p>Founded on July 7, 1968, Mazooba Growers Cooperative Society Ltd. is dedicated to uplifting the livelihood of communities through sustainable agricultural practices.</p>
          </div>
          <div className="footer-section">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="/whoweare">About Us</a></li>
              <li><a href="/services">Services</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="#privacy">Privacy Policy</a></li>
              <li><a href="#terms">Terms of Service</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Contact Us</h4>
            <ul>
            <li>Mubende Municipality, East Division
            <br></br>Five KMs along Mubende – Kakumiro Road</li>
            <li>P.O box 2, Mubende</li>
            <li>Phone: +256 752 212 314/+256772974579</li>
              <li>Email: mazoobagrowers@gmail.com</li>
              
              
            </ul>
          </div>
          <div className="footer-section">
            <h4>Follow Us</h4>
            <ul className="social-icons">
              <li><a href="#facebook" aria-label="Facebook"><FaFacebookF /></a></li>
              <li><a href="#twitter" aria-label="Twitter"><FaTwitter /></a></li>
              <li><a href="#linkedin" aria-label="LinkedIn"><FaLinkedinIn /></a></li>
              <li><a href="#instagram" aria-label="Instagram"><FaInstagram /></a></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2024 Mazooba Growers Cooperative Society Ltd. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};
