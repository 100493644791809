import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './navigation.css';

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById('navbar');
      if (window.scrollY > document.querySelector('.top-bar').clientHeight) {
        navbar.classList.add('fixed-navbar');
      } else {
        navbar.classList.remove('fixed-navbar');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* Topbar Start */}
      <div className="container-fluid top-bar px-5 d-none d-lg-block">
        <div className="row gx-5 py-3 align-items-center">
          <div className="col-lg-3">
            <div className="d-flex align-items-center justify-content-start">
              <i className="bi bi-phone-vibrate fs-1 text-primary me-2"></i>
              <h2 className="mb-0">+256 752 212 314</h2>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/" className="navbar-brand ms-lg-5">
                <h1 className="m-0 display-4 text-primary">
                  <img src="img/logo/logo.png" alt="Logo" className="full-width-logo" />
                </h1>
              </Link>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="d-flex align-items-center justify-content-end social-icons">
              <button className="btn btn-primary btn-square rounded-circle me-2">
                <i className="fab fa-twitter"></i>
              </button>
              <button className="btn btn-primary btn-square rounded-circle me-2">
                <i className="fab fa-facebook-f"></i>
              </button>
              <button className="btn btn-primary btn-square rounded-circle me-2">
                <i className="fab fa-linkedin-in"></i>
              </button>
              <button className="btn btn-primary btn-square rounded-circle">
                <i className="fab fa-instagram"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      {/* Navbar Start */}
      <nav id="navbar" className="navbar navbar-expand-lg bg-primary navbar-dark shadow-sm py-3 py-lg-0 px-3 px-lg-5">
        <Link to="/" className="navbar-brand d-flex d-lg-none">
          <h1 className="m-0 display-4 text-secondary">
            <img src="img/logo/logo.png" alt="Logo" className="full-width-logo" />
          </h1>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={handleToggle}
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarCollapse"
        >
          <div className="navbar-nav mx-auto py-0">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "nav-item nav-link active" : "nav-item nav-link")}
              onClick={handleLinkClick}
            >
              Home
            </NavLink>
            <NavLink
              to="/WhoWeAre"
              className={({ isActive }) => (isActive ? "nav-item nav-link active" : "nav-item nav-link")}
              onClick={handleLinkClick}
            >
              About
            </NavLink>
            <NavLink
              to="/Services"
              className={({ isActive }) => (isActive ? "nav-item nav-link active" : "nav-item nav-link")}
              onClick={handleLinkClick}
            >
              Service
            </NavLink>
            <NavLink
              to="/Products"
              className={({ isActive }) => (isActive ? "nav-item nav-link active" : "nav-item nav-link")}
              onClick={handleLinkClick}
            >
              Product
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? "nav-item nav-link active" : "nav-item nav-link")}
              onClick={handleLinkClick}
            >
              Contact
            </NavLink>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </>
  );
};
