import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faBullseye, faStar } from '@fortawesome/free-solid-svg-icons';
import "aos/dist/aos.css";
import AOS from 'aos';
import "./whoweare.css";
import { Link } from "react-router-dom";
import '@fontsource/roboto';
import '@fontsource/open-sans';

export const WhoWeAre = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <React.Fragment>
      <div className="all-title-box" data-aos="fade-down">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>ABOUT US</h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">ABOUT US</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="about-box-main" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-frame" data-aos="zoom-in">
                <img 
                  className="img-fluid" 
                  src="/img/about-img.jpg" 
                  alt="mazooba" 
                />
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left">
              <h2 className="noo-sh-title-top">
                Mazooba Growers Cooperative Society Ltd
              </h2>
              <p>
                Founded on July 7, 1968, Mazooba Growers Cooperative Society Ltd. (Mazooba Growers) is a vibrant, member-driven cooperative based in Uganda’s Central-Western Region covering the Districts of greater Mubende, Kyegewa, Kakumiro, Kibaale, Kasanda and the neighboring districts. Mazooba Growers is fully registered by the Ministry of Trade and Cooperatives under Registration No: RCS/1503.   
                We are a multi-integrated agricultural producer specializing in Coffee, Maize, Livestock and Beekeeping to uplift the livelihood of our communities. We aggregate production and add value through processing. We seek to pursue digital/smart agriculture transformation, e-farmer financing, online commodity trading, and exportation of value added produce.
              </p>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-sm-6 col-lg-4" data-aos="flip-left">
              <div className="service-block-inner">
                <FontAwesomeIcon icon={faEye} className="icon-color" size="3x" />
                <h3>Vision</h3>
                <p>To economically uplift farmers through value addition</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4" data-aos="flip-up">
              <div className="service-block-inner">
                <FontAwesomeIcon icon={faBullseye} className="icon-color" size="3x" />
                <h3>Mission</h3>
                <p>To promote climate-smart agricultural production, improve quality and productivity, market access, and access to affordable financing in line with government’s social-economic transformation agenda</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4" data-aos="flip-right">
              <div className="service-block-inner">
                <FontAwesomeIcon icon={faStar} className="icon-color" size="3x" />
                <h3>Core Values</h3>
                <p>Innovation, Integrity, Inclusion, Accountability and Transformation</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
