import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./products.css";

const productsData = [
    {
        id: 1,
        name: "Green Coffee",
        description: "Our Green Coffee is carefully handpicked from member farmers and carefully sorted and graded capturing the rich, natural flavors of single-origin Robusta coffee.",
        image: "/img/products/coffee-beans.jpg"
    },
    {
        id: 2,
        name: "Maize/Corn",
        description: "Our farmers produce Mubende Maize Brand which has over the years gained traction in neighboring markets of Kenya, Rwanda and South Sudan due to fertile soils, improved production and post-harvest handling.",
        image: "/img/products/maize-flour.jpg"
    },
    {
        id: 3,
        name: "Honey",
        description: "Sourced from the savanna lands of central western Uganda, our honey is a pure, natural treasure created by bees that thrive among coffee blossoms and wild flora.",
        image: "/img/products/honey.jpg"
    },
    {
        id: 4,
        name: "Livestock",
        description: "Blended agriculture provides our farmers with alternative income sources, organic manure, and bio-energy for cooking to promote environmental conservation.",
        image: "/img/products/livestock.jpg"
    },
    // Add more products as needed
];

export const Products = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    return (
        <div className="products-section">
            <h2 className="products-title">Our Products</h2>
            <div className="products-container">
                {productsData.map((product) => (
                    <div key={product.id} className="product-card" data-aos="fade-up">
                        <img src={product.image} alt={product.name} className="product-image" />
                        <h3 className="product-name">{product.name}</h3>
                        <p className="product-description">{product.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Products;
