import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './header.css'; // Your custom styles

export const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Duration of animation
    });
  }, []);

  const slides = [
    {
      image: '/img/intro-bg1.jpg',
      text: "Join us in transforming agriculture.",
      title: "Our Dream",
      subtitle: "To alleviate poverty through agricultural value addition",
      description: "",
      cta: "Learn More"
    },
    {
      image: '/img/intro-bg2.jpg',
      text: "Promoting sustainable agricultural production.",
      title: "Our Plan",
      subtitle: "",
      description: "Maximize earnings through agro-processing, expand employment and household incomes, build capacity in modern agricultural production.",
      cta: "Discover More"
    },
    {
      image: '/img/intro-bg3.jpg',
      text: "Upholding our core values.",
      title: "Our Principles",
      subtitle: "",
      description: "Innovation, Integrity, Inclusion, Accountability, and Professionalism",
      cta: "Our Values"
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    pauseOnHover: true
  };

  return (
    <section className="main">
      <div className="slider-container" data-aos="fade-up">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className="slide" data-aos="fade-in">
              <img src={slide.image} alt={`Slide ${index + 1} - ${slide.title}`} className="image" />
              <div className="overlay"></div> {/* Overlay div */}
              <div className="image-data">
                <span className="text">{slide.text}</span>
                <h1>{slide.title}</h1>
                <h3>{slide.subtitle}</h3>
                <p>{slide.description}</p>
                <a href="#features" className="button">{slide.cta}</a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Header;
