import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Values.css';

export const Values = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const values = [
    { title: "Innovation", description: "We strive to bring new ideas to life.", icon: "💡", ariaLabel: "Innovation Icon" },
    { title: "Integrity", description: "We uphold the highest standards of integrity.", icon: "🔒", ariaLabel: "Integrity Icon" },
    { title: "Inclusion", description: "We embrace diversity and inclusion.", icon: "🌍", ariaLabel: "Inclusion Icon" },
    { title: "Accountability and Transformation", description: "We are accountable for our actions and drive transformation.", icon: "🔄", ariaLabel: "Accountability and Transformation Icon" },
  ];

  return (
    <div className="values-section" data-aos="fade-up">
      <div className="values-header" data-aos="fade-down">
        <h1>Our Core Values</h1>
        <p>Mazooba Growers Cooperative Society Ltd</p>
      </div>
      <div className="values-content">
        <div className="values-image" data-aos="zoom-in">
          <img src="/img/intro-bg2.jpg" alt="Strategic Blueprint" />
        </div>
        <div className="values-container">
          {values.map((value, index) => (
            <div key={index} className="value-card" data-aos="flip-left">
              <div className="value-card-icon" aria-label={value.ariaLabel}>{value.icon}</div>
              <div className="value-card-content">
                <h2>{value.title}</h2>
                <p>{value.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Values;
