import React from "react";
import "./contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

export const Contact = () => {
  return (
    <section>
      <div className="contact-us-container">
        <div className="contact-info">
          <div className="background-image">
            <div className="info-overlay">
              <h3>Contact Information</h3>
              <p>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Mubende Municipality, East Division Five KMs along Mubende – Kakumiro Road
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} /> +256 752 212 314/+256772974579
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} /> mazoobagrowers@gmail.com
              </p>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <h3>Get in Touch </h3>
          <form>
            <label>
              Full Name
              <input
                type="text"
                name="fullname"
                placeholder="Enter your name"
                required
              />
            </label>
            <label>
              Email
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                required
              />
            </label>
            <label>
              Message
              <textarea
                name="message"
                placeholder="Enter your message"
                required
              ></textarea>
            </label>
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};
