import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles
import { Navigation } from "./components/navigation/navigation";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Loader } from "./components/loader/Loader";
import AppRoutes from './AppRoutes';
import { Footer } from "./components/footer/footer";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLandingPageData(JsonData);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Router basename="/">
      <div>
        <Navigation />
        <AppRoutes landingPageData={landingPageData} />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
