import React from "react";
import './process.css';

export const OurProcess = () => {
  const categories = [
    { img: "img/categories_img_01.jpg", name: "Category 1" },
    { img: "img/categories_img_02.jpg", name: "Category 2" },
    { img: "img/categories_img_03.jpg", name: "Category 3" },
  ];

  return (
    <div className="categories">
      <div className="container">
        <div className="row">
          {categories.map((category, index) => (
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={index} data-aos="fade-up">
              <div className="box bounce-animation">
                <img className="img-fluid" src={category.img} alt={`${category.name}`} />
                <button className="btn hvr-hover" aria-label={`Button for ${category.name}`}>{category.name}</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
