import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faLeaf, faCow, faDrumstickBite } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './ToneOfVoice.css';

export const ToneOfVoice = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const items = [
    { icon: faCoffee, title: "Coffee" },
    { icon: faLeaf, title: "Maize" },
    { icon: faCow, title: "Livestock" },
    { icon: faDrumstickBite, title: "Beekeeping" },
  ];

  return (
    <div className="agriculture-info" data-aos="fade-up">
      <h2 className="agriculture-title" data-aos="fade-up">multi-integrated</h2>
      <div className="agriculture-cards">
        {items.map((item, index) => (
          <div key={index} className="agriculture-card" data-aos="flip-left">
            <FontAwesomeIcon icon={item.icon} className="agriculture-icon" />
            <p className="agriculture-card-title">{item.title}</p>
          </div>
        ))}
      </div>
      <p className="agriculture-text" data-aos="fade-up">
        We are a multi-integrated agricultural producer specializing in Coffee, Maize, Livestock, and Beekeeping to uplift the livelihood of our communities.
      </p>
    </div>
  );
};

export default ToneOfVoice;
