import React, { useEffect } from "react";
import "./services.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling, faTruck, faWarehouse } from '@fortawesome/free-solid-svg-icons';

const servicesData = [
  {
    id: 1,
    icon: faSeedling,
    name: "Capacity Building",
    text: "Farmer capacity building in modern agricultural practices.",
    image: "path/to/image1.jpg",
    details: ""
  },
  {
    id: 2,
    icon: faTruck,
    name: "Quality Inputs",
    text: "Source quality farm input for our member farmers.",
    image: "path/to/image2.jpg",
    details: ""
  },
  {
    id: 3,
    icon: faWarehouse,
    name: "Agro-Processing",
    text: "Bulking, agro-processing, storage, and marketing.",
    image: "path/to/image3.jpg",
    details: ""
  }   
];

export const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  
  if (!servicesData) {
    return <p>Loading services...</p>;
  }

  if (servicesData.length === 0) {
    return <p>No services available at the moment.</p>;
  }

  return (
    <>
      {/* Hero Start */}
      <div className="services-container-fluid bg-primary py-5 bg-hero mb-5">
        <div className="services-container py-5">
          <div className="row justify-content-start">
            <div className="col-lg-8 text-center text-lg-start" data-aos="fade-right">
              <h1 className="display-1 text-white mb-md-4">Our Services</h1>
              <a href="/" className="btn btn-primary py-md-3 px-md-5 me-3">Home</a>
              <a href="/services" className="btn btn-secondary py-md-3 px-md-5">Services</a>
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}

      {/* Services Start */}
      <div className="services-container-fluid py-5">
        <div className="services-container">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6" data-aos="fade-up">
              <div className="mb-3">
                <h6 className="text-primary text-uppercase">Services</h6>
              </div>
              <p className="mb-4">At Mazooba Growers Cooperative Society we understand that the agricultural value chain is a composite of complex systems that must work in tandem to achieve results. Therefore, our cooperative society hybridized a model to deliver the following key services:</p>
              <a href="/contact" className="btn btn-primary py-md-3 px-md-5">Contact Us</a>
            </div>
            {servicesData.map((service, index) => (
              <div key={index} className="col-lg-4 col-md-6" data-aos="fade-up">
                <div className="service-item bg-light text-center p-5">
                  <FontAwesomeIcon icon={service.icon} className={`icon-${service.id} display-1 mb-3`} />
                  <h4>{service.name}</h4>
                  <p className="mb-0">{service.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Services End */}
    </>
  );
};

export default Services;
