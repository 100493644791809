import React, { useState } from "react";
import "./faqs.css";

export const Faqs = () => {
  const faqs = [
    {
      category: "General Questions",
      questions: [
        {
          question: "What is a performance audit, and why is it important for Sub-Saharan Africa?",
          answer:
            "A performance audit evaluates how efficiently, effectively, and economically an organization’s operations meet their objectives. In Sub-Saharan Africa, performance audits drive governance reforms, enhance transparency, and ensure efficient use of resources, aligning with AFROSAI and INTOSAI standards.",
        },
        {
          question: "How do performance audits follow INTOSAI guidelines?",
          answer:
            "Performance audits adhere to INTOSAI frameworks by focusing on the three Es—economy, efficiency, and effectiveness—and providing a structured approach to assessing and improving organizational performance.",
        },
        {
          question: "Who benefits the most from performance audits?",
          answer:
            "Public sector agencies, corporations, and development partners benefit significantly, ensuring operations are streamlined, governance is transparent, and compliance requirements are met.",
        },
      ],
    },
    {
      category: "Public Sector-Specific Questions",
      questions: [
        {
          question: "Why are performance audits crucial for public agencies in Sub-Saharan Africa?",
          answer:
            "Public sector agencies use performance audits to improve governance, meet donor compliance requirements, and achieve accountability. They are integral to reducing corruption and aligning with governance reforms.",
        },
        {
          question: "How do your services support government agencies in adopting INTOSAI standards?",
          answer:
            "We design capacity-building initiatives tailored to public sector needs, ensuring compliance with INTOSAI’s Performance Audit Standards while addressing resource and skill gaps.",
        },
      ],
    },
    {
      category: "Development Partners-Led Initiatives Questions",
      questions: [
        {
          question: "Why are performance audits crucial for public agencies in Sub-Saharan Africa?",
          answer:
            "Development partners increasingly require performance audits to ensure transparency, accountability, and the effective use of their funds in achieving project goals across Sub-Saharan Africa.",
        },
        {
          question: "How do your services support donor-funded projects?",
          answer:
            "We design customized training modules that address donor-specific compliance requirements and equip local organizations with the tools to conduct reliable performance audits.",
        },
        {
          question: "Do you work with donors to provide capacity-building programs?",
          answer:
            "Yes, we partner with major development partners like USAID, DFID, and the World Bank to co-develop and subsidize training initiatives, ensuring alignment with international best practices and local contexts.",
        },
        {
          question: "Can your programs help organizations meet changing donor compliance requirements?",
          answer:
            "Absolutely. Our solutions are tailored to adapt to evolving donor accountability standards, ensuring organizations remain compliant while improving performance.",
        },
        
        {
          question: "Are your services accessible to grassroots organizations implementing donor projects?",
          answer:
            "Yes, we provide cost-effective and scalable training formats, including online and hybrid models, to make performance audit training accessible to grassroots organizations in remote areas.",
        },
      ],
    },
    {
      category: "Corporate Sector-Specific Questions",
      questions: [
        {
          question: "How can performance audits improve corporate governance in Sub-Saharan Africa?",
          answer:
            "Performance audits help corporations streamline compliance, optimize operations, and manage risks effectively, particularly in industries like energy, telecommunications, and finance.",
        },
        {
          question: "What technology-focused training do you offer for corporations?",
          answer:
            "Our advanced modules include AI-driven audit technologies and big data analytics to meet the growing demand for technology integration in corporate audits.",
        },
        {
          question: "Do you work with donors to provide capacity-building programs?",
          answer:
            "Yes, we partner with major development partners like USAID, DFID, and the World Bank to co-develop and subsidize training initiatives, ensuring alignment with international best practices and local contexts.",
        },
        
      ],
    },
    {
      category: "Technology and Innovation",
      questions: [
        {
          question: "How does AI improve performance audits?",
          answer:
            "AI streamlines compliance processes, enhances data analysis accuracy, and provides actionable insights, making audits more efficient and impactful. We provide practical training in these areas.",
        },
        {
          question: "Can your technology-focused training integrate with legacy audit systems?",
          answer:
            "Yes, we specialize in creating tailored training programs that help organizations transition from traditional methods to AI-driven systems while ensuring seamless integration.",
        },
        
      ],
    },
    {
      category: "Practicalities and Contact",
      questions: [
        {
          question: "What training formats are available for your programs?",
          answer:
            "We offer in-person, hybrid, and online training options to accommodate diverse needs, including those of remote or resource-constrained organizations.",
        },
        {
          question: "How can we engage your team for our audit and training needs?",
          answer:
            "Reach out to us via our website, email, or phone. We’ll provide a tailored approach to meet your specific requirements.",
        },
        {
          question: "Do you organize regional workshops or collaborative events?",
          answer:
            "Yes, we regularly host webinars, workshops, and regional conferences to share best practices, build networks, and strengthen performance audit capacity across Sub-Saharan Africa.",
        },
        
      ],
    },
  ];

  return (
    <div id="faq">
      <h2>Frequently Asked Questions</h2>
      {faqs.map((section, index) => (
        <div key={index} className="faq-category">
          <h3 className="faq-category-title">{section.category}</h3>
          {section.questions.map((item, i) => (
            <FAQItem key={i} question={item.question} answer={item.answer} />
          ))}
        </div>
      ))}
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <div
        className="faq-question"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <h4>{question}</h4>
        <span className="faq-icon">{isOpen ? "−" : "+"}</span>
      </div>
      {isOpen && <p className="faq-answer">{answer}</p>}
    </div>
  );
};
