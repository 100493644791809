// components/Loader/Loader.js
import React from 'react';
import './Loader.css'; // Import the CSS file for styling

export const Loader = () => {
  return (
    <div className="loader">
      <div className="plant">
        <div className="stem"></div>
        <div className="leaf"></div>
      </div>
    </div>
  );
};
