import React, { useEffect, useState, useRef } from "react";
import './features.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Features = ({ data }) => {
  const [cards, setCards] = useState([]);
  const carouselRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const totalCards = data ? data.length * 3 : 0; // Triple the data for smoother looping

  useEffect(() => {
    if (data) {
      setCards([...data, ...data, ...data]); // Triple the data
    }

    AOS.init({
      duration: 800, // duration of the animation
      easing: 'ease-in-out', // easing function
      once: true // whether animation should happen only once
    });
  }, [data]);

  useEffect(() => {
    AOS.refresh(); // refresh AOS on data change or other updates

    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % (data ? data.length : 1));
      const scrollPosition = 
        carouselRef.current && totalCards 
          ? (carouselRef.current.scrollWidth / totalCards) * ((activeIndex + 1) % totalCards)
          : 0;
      if (carouselRef.current) {
        carouselRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [activeIndex, data, totalCards]);

  const handleDotClick = (index) => {
    setActiveIndex(index);
    const scrollPosition = 
      carouselRef.current && totalCards 
        ? (carouselRef.current.scrollWidth / totalCards) * index
        : 0;
    if (carouselRef.current) {
      carouselRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    }
  };

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="section-title">
          <h3>Our Core Capabilities</h3>
        </div>
        <div className="carousel-container">
          <div className="carousel" ref={carouselRef}>
            {cards.map((d, i) => (
              <div 
                key={`${d.title}-${i}`} 
                className="feature-card" 
                data-aos="fade-up" // AOS animation attribute
              >
                <div className="feature-content">
                  <img src={d.image} alt={d.title} className="feature-image" />
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="carousel-dots">
          {data && data.map((_, index) => (
            <span
              key={index}
              className={`dot ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
      <div className="plant plant1"></div>
      <div className="plant plant2"></div>
      <div className="plant plant3"></div>
      <div className="plant plant4"></div>
      <div className="plant plant5"></div>
    </div>
  );
};
